import { createI18n } from 'vue-i18n'
import messages from '@intlify/unplugin-vue-i18n/messages'

const env = import.meta.env

export const i18n = createI18n({
  locale: env.VITE_I18N_LOCALE || 'en',
  fallbackLocale: env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: env.MODE !== 'development', // changed from old code, NODE_ENV is not the frontend vue way to look at things
  messages
})
